































































































import { Component, Watch } from 'vue-property-decorator';
import BaseClass from '@/base';
import papa from 'papaparse';
import firebase from '@/plugins/firebase';

import { v4 as uuidv4 } from 'uuid';
import config from '@/../config';

@Component
export default class ImportCsv extends BaseClass {
    // handle upload event
    seekers: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        jobAdId: string;
        jobPortal: string;
        status: string;
        cv: File;
    }[] = [];

    uploading = false;
    jobAds: firebase.firestore.DocumentData[] = [];

    async created() {
        await this.$bind(
            'jobAds',
            firebase
                .firestore()
                .collection('posts')
                .where('status', '==', 'Approved')
        );

        console.log(this.jobAds);
    }

    async handleUpload(event: Event) {
        this.seekers = [];
        const target = event.target as HTMLInputElement;
        const files = target.files;

        if (!files) {
            return;
        }

        const file = files[0];

        const x = await file.text();
        const data = papa.parse(x, { header: true })?.data;

        if (!data) {
            return;
        }

        this.seekers.push(
            ...data.map((i: any) => {
                const x = /jobs\/([a-zA-Z0-9]+)/g.exec(i.jobAdId);
                let jobAdId = '';
                if (x && x.length > 0 && x[1]) {
                    jobAdId = x[1];
                } else {
                    jobAdId = i.jobAdId;
                }

                console.log(jobAdId);

                return {
                    ...(i as Record<string, any>),
                    jobAdId: jobAdId,
                    selected: true,
                    status: 'Pending',
                } as any;
            })
        );

        this.seekers = this.seekers.filter(
            (x) => x.firstName && x.lastName && x.email
        );

        // remove dupes
    }

    async upload() {
        this.uploading = true;
        for (const seeker of this.seekers) {
            try {
                if (!seeker.cv?.name) {
                    throw new Error('No CV attached.');
                }

                seeker.status = 'Uploading';
                const userExistRef = await this.firebase
                    .firestore()
                    .collection(`users`)
                    .where('email', '==', seeker.email)
                    .get();
                if (userExistRef.size > 0) {
                    throw new Error(`User already exists.`);
                }

                const tempPassword = this.generatePassword();

                const jobAd = this.jobAds.find(
                    (x) => x.id === seeker.jobAdId
                );

                const userData: any = {
                    ...seeker,
                    firstName: seeker.firstName,
                    lastName: seeker.lastName,
                    accountType: 'job_seeker',
                    nickName: '',
                    phoneNumber: seeker.phoneNumber,
                    gender: 'Unspecified',
                    email: seeker.email,
                    degree: `Bachelor's Degree`,

                    educationalAttainment: '',
                    about: '',
                    age: 0,
                    city: {
                        id: '',
                        name: '',
                    },
                    displayName: `${seeker.firstName} ${seeker.lastName}`,
                    github: '',
                    linkedin: '',
                    birthDate: '',
                    occupation: '',
                    openForJob: true,
                    openForRemote: true,
                    photos: {
                        large: '',
                        medium: '',
                        original: '',
                        small: '',
                    },
                    preferredJobType: 'Full Time',
                    skillsArray: [],
                    skills: [],
                    tempPassword: tempPassword,
                    skillsComplete: [],
                    setupDone: true,
                    workExperience: [],
                    fromImport: true,
                    fromCsvImport: true,
                    preApplyData:
                        jobAd === undefined
                            ? null
                            : {
                                  isJobAdOpen: jobAd.status === 'Open',
                                  jobAdId: jobAd.id,
                              },
                };

                delete userData.status;
                delete userData.selected;
                delete userData.jobAdId;

                const createFn = this.firebase
                    .app()
                    .functions('asia-east2')
                    .httpsCallable('admin-createUser');
                const result = await createFn({
                    email: seeker.email,
                    password: tempPassword,
                    displayName: `${seeker.firstName} ${seeker.lastName}`,
                    userData: userData,
                    isResumeSetup: true,
                });

                seeker.status = 'Uploading CV';

                // upload CV
                const resumeId = await this.uploadCV(
                    seeker.cv,
                    result.data.uid
                );

                // apply to position

                const uid = result.data.uid;
                if (!uid) {
                    throw new Error('Failed to get user ID.');
                }

                if (jobAd) {
                    const batch = this.firebase.firestore().batch();

                    const applicationRef = this.firebase
                        .firestore()
                        .collection(`applications`)
                        .doc();
                    batch.set(applicationRef, {
                        user: {
                            id: uid,
                            photo: '',
                        },
                        post: {
                            id: jobAd.id,
                        },
                        suppressEmailNotification: true,
                        status: 'Pending',
                        forceChangePassword: true,
                        forceScreeningQuestions: true,
                        noticePeriod: '',
                        employmentStatus: '',
                        coverLetter:
                            'System generated via Jobseeker Import',
                        screeningAnswers: [],
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        resume: {
                            id: resumeId,
                        },
                        importFromCsv: true,
                    });

                    batch.set(
                        this.firebase
                            .firestore()
                            .doc(`users/${uid}/applications/${jobAd.id}`),
                        {
                            applicationId: applicationRef.id,
                        }
                    );

                    await batch.commit();
                }

                seeker.status = 'Uploaded';
            } catch (error) {
                seeker.status = error.message;
            }
        }

        this.uploading = false;
    }

    private generatePassword() {
        return Math.random().toString(36).substr(2, 8);
    }

    private async uploadCV(file: File, uid: string) {
        // Get extension from filename
        const extension = file.name.split('.').pop();

        // Generate a uuidV4 Filename using the file extension
        const fileName = `${uuidv4()}.${extension}`;

        // Upload resume
        const upload = await firebase
            .app()
            .storage(config.buckets.cv)
            .ref(`${uid}/${fileName}`)
            .put(file);

        if (upload.state !== 'success') {
            throw new Error('failed to upload resume. Please try again.');
        }

        const resp = await firebase
            .firestore()
            .collection(`users/${uid}/resume`)
            .add({
                storagePath: `${uid}/${fileName}`,
                fileName: file.name,
                fileSize: file.size,
                fileType: file.type,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });

        return resp.id;
    }
}
